<template>
  <div class="tools-collection-container" ref="rootRef">
    <ul class="tools-collection" ref="toolListRef">
      <li v-for="collection in toolList" :key="collection.name">
        <h3 class="collection-title">{{ collection.name }}</h3>
        <ul class="tool-list">
          <li v-for="tool in collection.list" :key="collection.title" @click="handleClick(tool)">
            <div class="preview-container">
              <v-render :vnode="tool.preview"/>
              <div v-if="tool.description" class="tool-description">
                {{ tool.description }}
              </div>
            </div>
            <h4 class="tool-title">{{ tool.title }}</h4>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { defineExpose, ref, onMounted, onBeforeUnmount } from "vue";
import { toolList } from './data.config';
import { eventTracking } from '@/utils/eventTracking.js';
import { useRouter } from 'vue-router';
import { showModal, currentToolName } from "../viewModel";

const rootRef = ref(null);
const toolListRef = ref(null);
const router = useRouter();
const handleClick = (item) => {
  eventTracking("booltool_homepage_click", { tool_name: item.trackName, trigger_location: 'main' })
  // if (["/face-swapper", "/video-background-remove", "/video-inpainting"].includes(item.path)) {
  //   currentToolName.value = item.trackName;
  //   showModal.value = true;
  //   return
  // }
  const url = router.resolve({ path: item.path }).href;
  window.open(url, '_blank')
}

function handleElementResize() {
  const base = toolListRef.value.querySelector('.tool-list > li').clientWidth / 13;
  toolListRef.value.style.fontSize = `${Math.max(22, Math.min(32, base | 0))}px`;
}

const observer = new ResizeObserver(handleElementResize);
onMounted(() => {
  observer.observe(toolListRef.value);
});

onBeforeUnmount(() => {
  observer.disconnect();
});

defineExpose({ root: rootRef })
</script>

<style scoped>
.tools-collection {
  @apply w-lv;

  padding-top: 6em;
  margin-inline: auto;
  text-align: left;
}

.tools-collection > li {
  margin-bottom: 6em;
}

.collection-title {
  margin-bottom: 1.5em;
  font-size: min(1.2em, 36px);
  font-weight: 600;
}

.tool-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: space-between;
  grid-gap: 2em 40px;
}

.preview-container {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  clip-path: inset(1px);
  aspect-ratio: 390 / 260;
  cursor: pointer;
}

.preview-container:hover > .tool-description {
  top: 50%;
  opacity: 1;
  pointer-events: all;
}

.tool-description {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  bottom: 0;
  padding: 24px;
  opacity: 0;
  font-size: .48em;
  line-height: 140%;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.75);
  transition: top 0.06s, opacity .06s;
  overflow: hidden;
  pointer-events: none;
}

.tool-title {
  margin-top: .667em;
  font-size: .667em;
  font-weight: 600;
}

@media screen and (max-width: 760px) {
  .tool-list {
    grid-gap: 2em 30px;
    grid-template-columns: 1fr 1fr;
  }
}

@screen m {
  .tools-collection {
    @apply w-mv;

    padding-top: 5em;
  }

  .tools-collection > li {
    margin-bottom: 5em;
  }
}

@screen s {
  .tools-collection {
    @apply w-sv;

    padding-top: 4em;
  }

  .tools-collection > li {
    margin-bottom: 4em;
  }

  .tool-list {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    font-size: .9em;
  }

  .tool-title {
    font-weight: 500;
  }

  .tool-description {
    font-size: .54em;
  }

  .collection-title {
    margin-bottom: 1.5em;
    font-size: min(.74em, 36px);
  }
}
</style>
